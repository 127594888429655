import * as React from "react"

import PickerScreen from '../../../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="What's your goal?"
    options={[
      {
        label: "I want to create an interactive prototype",
        link: "/app/digital-product-design/hi-fi/interactive-prototype/"
      },
      {
        label: "I want to create a static design",
        link: "/app/digital-product-design/hi-fi/static-design/"
      }
  ]}/>
)

export default Picker;


